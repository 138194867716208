import http from "./http";

import request from "./request"


export const login = (params) => {
    return http.post("login", params);
};
export const Logout = () => {
    return http.get("login_out");
};

//分类管理-分类列表
export const commodityList = (params) => {
    return http.get("category/list", { params: params });
};
//分类管理-创建分类
export const addcommodity = (params) => {
    return http.post("category/add", params);
};
//分类管理-删除分类
export const delcommodity = (params) => {
    return http.post("category/del", params);
};


/**商品库 */
//近30天数据
export const createGoodsData = (params) => {
    return http.post("api/admin/goods/createGoodsData", params);
};
//富文本编辑器图片上传
export const theoryTopicUploadRtImg = (params) => {
    return http.post("api/admin/goods/goodsUpload", params);
};
//普通发品最近使用类目
export const recentlyList = (params) => {
    return http.get("api/admin/goods/saveOldUseCategory", { params: params });
};
//获取最近使用的类目
export const adminGoodRecentlyUsedCategory = (params) => {
    return http.get("api/admin/goods/getOldUseCategory", { params: params });
};
//普通发品列表
export const goodsList = (params) => {
    return http.get("api/admin/goods/goodsCategory", { params: params });
};

//验证商品信息
export const validateGoodsInfo = (params) => {
    return http.post("api/admin/goods/validateGoodsInfo", params);
};
//验证商品数据
export const validateGoodsData = (params) => {
    return http.post("api/admin/goods/validateGoodsData", params);
};
//验证模板数据
export const validateGoodsTemplate = (params) => {
    return http.post("api/admin/goods/validateGoodsTemplate", params);
};
//验证商品优惠券
export const validateGoodsCoupon = (params) => {
    return http.post("api/admin/goods/validateGoodsCoupon", params);
};
//验证商品问答
export const validateGoodsQuestion = (params) => {
    return http.post("api/admin/goods/validateGoodsQuestion", params);
};
//商品库保存
export const save = (params) => {
    return http.post("/api/admin/goods/save", params);
};
//商品库列表
export const commodityDate = (params) => {
    return http.get("api/admin/goods/list", { params: params });
};
//商品库列表删除
export const commodityDel = (params) => {
    return http.post("api/admin/goods/del", params);
};
//商品库复制
export const copyGoods = (params) => {
    return http.get("api/admin/goods/copyGoods", { params: params });
};
//导出数据
export const createGoodsDataExcel = (params) => {
    return http.post("api/admin/goods/createGoodsDataExcel", params);
};

/**教务-学校 */
export const getSchoolListData = (params) => http.get("school", { params });

export const sendOperateSchool = (params) => http.post("school/add", params);

export const sendDeleteSchool = (params) => http.get(`school/del/${params.id}`);

export const sendSchoolAssignPaper = (params) => http.post("school_paper/save", params);

export const sendGetAlreadyAssignPaper = (params) => http.get("school_paper", { params });

export const sendSchoolUploadLogo = (params) => http.post("school_paper/save", params);

/**教务-学校管理员 */
export const schoolAdminUploadLogo = (params) => http.post("school_user/upload", params);

export const addSchoolAdmin = (params) => http.post("school_user/add", params);

export const editSchoolAdmin = (params) => http.post("school_user/edit", params);

export const getSchoolAdminListData = (params) => http.get("school_user/index", { params });

export const deleteSchoolAdmin = (params) => http.get(`school_user/del/${params.id}`);

/**教育-训练管理员 */
export const trainAdminUploadLogo = (params) => http.post("train_user/upload", params);

export const addTrainAdmin = (params) => http.post("train_user/add", params);

export const editTrainAdmin = (params) => http.post("train_user/edit", params);

export const getTrainAdminListData = (params) => http.get("train_user/index", { params });

export const deleteTrainAdmin = (params) => http.get(`train_user/del/${params.id}`);

export const bagList = (params) => {
    return http.get("bag", { params });
}
export const delBag = (id) => {
    return http.get('bag/del/' + id)
}
export const saveBag = (params) => {
    return http.post("bag/save", params);
}
export const getBagInfo = (id) => {
    return http.get('bag/info/' + id)
}
export const getBagCommand = (params) => {
    return http.get("bag_command", params);
}

/**学校管理端-教务管理*/
// 教师列表
export const getTeacherListData = (params) => {
        return http.get('teacher_manage/index', { params });
    }
    // 添加教师
export const addTeacher = (params) => {
        return http.post('teacher_manage/add', params)
    }
    //上传头像
export const uploadTeacherAvatar = (params) => {
        return http.post('teacher_manage/upload', params)
    }
    // 编辑教师信息
export const editTeacher = (params) => {
        return http.post('teacher_manage/edit', params)
    }
    // 删除教师
export const delTeacher = (id) => {
        return http.get('teacher_manage/del/' + id)
    }
    //实训中心列表
export const practicalListData = (params) => {
    return http.get("api/teacher/train/list", { params: params });
};
//实训中心查看直播列表
export const getOtherLive = (params) => {
        return http.get('api/teacher/train/getOtherLive', { params });
    }
    /**班级管理 */
export const operateClass = (params) => http.post("banji/add", params);

export const getClassListData = (params) => http.get("banji/list", { params });

export const deleteClass = (params) => http.post("banji/del", params);

/**学生管理 */
export const uploadStudentAvatar = (params) => http.post("student_manage/upload", params);

export const operateStudent = (params) => http.post("student_manage/add", params);

export const deleteStudent = (params) => http.post("student_manage/del", params);

export const downloadStudentTemplate = (params) => http.get("student_manage/downloadStudentTem", { params });

export const getStudentListData = (params) => http.get("student_manage/list", { params });

export const importStudent = (params) => http.post("student_manage/importStudent", params);

/**脚本管理 */
// 列表
export const showScriptListData = (params) => {
        return http.get('script/', { params })
    }
    // 列表删除
export const delScriptListData = (params) => {
        return http.get('script/del/', { params })
    }
    //脚本创建
export const createScriptData = (params) => {
        return http.post('script/save', params)
    }
    //脚本编辑
export const editScriptData = (params) => {
        return http.get('script/info/' + params)
    }
    // 商品分类
export const showGoodListData = (params) => {
        return http.get('api/admin/goods/list', { params })
    }
    // 获取商品模板数据
export const showTmpData = (params) => {
    return http.post('api/admin/goods/scriptGoodsTemplateData', params)
}

/**实训试卷 */
export const getPracticalTrainData = (params) => http.get("api/admin/paper_template/list", { params })

export const operatePracticalTrain = (params) => http.post("api/admin/paper_template/save", params)

export const deletePracticalTrain = (params) => http.post("api/admin/paper_template/del", params)

export const adList = (params) => {
    return http.get('advertising/list', { params })
}
export const delAd = (params) => {
    return http.post('advertising/del', params)
}
export const live_script_goods = (params) => {
    return http.get('live_script/goods', { params })
}
export const live_script_goods_info = (id) => {
    return http.get('live_script/goods_info/' + id)
}
export const live_script_info = (params) => {
    return http.get('live_script/info', { params })
}
export const live_script_goods_time = (params) => {
    return http.get('live_script/goods_time', { params })
}
export const live_script_goods_data = (id) => {
    return http.get('live_script/goods_data/' + id)
}
export const getStudentSetScriptGoodsData = () => http.get('live_script/get_goods_time');
export const advertising_getScriptList = (params) => {
        return http.get('advertising/getScriptList', { params })
    }
    //创建实训试卷列表
export const getSchoolPaper = (params) => {
        return http.get('api/teacher/train/getSchoolPaper', { params })
    }
    //创建实训试卷总时长
export const getScriptTime = (params) => {
        return http.get('api/teacher/train/getScriptTime', { params })
    }
    //创建实训试卷
export const practicalSave = (params) => {
        return http.post('api/teacher/train/save', params)
    }
    //实训中心训练状态
export const changeStatus = (params) => {
        return http.post('api/teacher/train/changeStatus', params)
    }
    //实训中心删除
export const practicalDel = (params) => {
    return http.post('api/teacher/train/del', params)
}

/** 学生端-直播营销-福袋 */
// 福袋列表
export const luckyBagList = (params) => {
        return http.get('marketing/luckyBagList', { params })
    }
    // 创建编辑福袋
export const createOrEditLuckyBag = (params) => {
        return http.post('marketing/addLuckyBag', params)
    }
    // 删除福袋
export const deleteLuckyBag = (params) => {
        return http.post('marketing/delLuckyBag', params)
    }
    //福袋详情
export const getLuckyBagDetail = (params) => {
        return http.get('marketing/luckyBagDetail', { params })
    }
    /** 学生端-直播营销-优惠券 */
export const couponList = (params) => {
        return http.get('marketing/couponList', { params })
    }
    //创建优惠券
export const addCoupon = (params) => {
        return http.post('marketing/addCoupon', params)
    }
    //创建优惠券编辑
export const couponDetail = (params) => {
        return http.get('marketing/couponDetail', { params })
    }
    //删除优惠券
export const delCoupon = (params) => {
        return http.post('marketing/delCoupon', params)
    }
    //查看其他学生直播
export const otherPeople = (params) => {
        return http.get('onlive/index', { params })
    }
    //获取脚本id
export const otherScript = (params) => {
    return http.get('onlive/get_script', { params })
}

/** 学生端-直播推广- 市场数据*/
// 图表数据
export const getMarketData = (params) => {
        return http.get('advertising_student/marketData', { params })
    }
    //直播
export const getLiveScriptData = (params) => http.get(`live_script/info/${params.id}`);

export const getSteamLocation = (params) => http.post("api/stu/cloudLive", params);

export const advertising_generateData = (params) => {
    return http.post('advertising/generateData', params)
}
export const advertising_add = (params) => {
    return http.post('advertising/add', params)
}
export const advertising_getCity = (params) => {
    return http.get('advertising/getCity', { params })
}
export const advertising_detail = (params) => {
    return http.get('advertising/detail', { params })
}

/** 学生端-直播推广-广告投放 */
// 广告列表
export const showAdvertisingListData = (params) => {
        return http.get('advertising_student/list', { params })
    }
    // 广告列表删除
export const delAdvertisingData = (params) => {
        return http.post('advertising_student/del', params)
    }
    // 获取投放基本信息
export const showBasicData = (params) => {
        return http.get('advertising_student/getDropDownData', { params })
    }
    // 添加广告
export const addAd = (params) => {
        return http.post('advertising_student/add', params)
    }
    // 编辑广告
export const editAd = (params) => {
        return http.get('advertising_student/detail', { params })
    }
    // 获取广告定向数据
export const getAdInfoData = (params) => {
    return http.get('advertising_student/getEstimateData', { params })
}
export const live_script_goods_script = (id) => {
    return http.get('live_script/goods_script/' + id)
}
export const live_script_save_goods = (params) => {
    return http.post('live_script/save_goods', params)
}
export const live_script_save = (params) => {
    return http.post('live_script/save', params)
}
export const live_script_intro_list = (params) => {
    return http.get('live_script/intro_list', { params })
}
export const live_script_intro_goods = (id) => {
    return http.get('live_script/intro_goods/' + id)
}
export const liveRoom_list = (params) => {
    return http.get('api/stu/liveRoom/list', { params })
}
export const liveRoom_del = (params) => {
        return http.post('api/stu/liveRoom/del', params)
    }
    /* 数据看板 */
    //获取观众来源
export const getAudienceData = (params) => {
        return request.get('data_board/getAudienceChart', { params })
    }
    // 获取销量
export const getSaleVolumeData = (params) => {
        return request.get('data_board/getSalesChart', { params })
    }
    // 获取主板块数据
export const getMainPanelData = (params) => {
        return request.get('data_board/getMainPlate', { params })
    }
    //获取弹幕
export const getBarrageData = (params) => {
        return request.get('data_board/getBarrage', { params })
    }
    // 获取商品列表
export const getGoodsDataList = (params) => {
        return request.get('data_board/getGoodsList', { params })
    }
    // 获取点赞数
export const getLikeList = (params) => {
        return request.get('data_board/getLikeChart', { params })
    }
    // 获取在线人数
export const getOnlineNumber = (params) => {
        return request.get('data_board/getOnliveNumberChart', { params })
    }
    // 获取福袋
export const getBag = (params) => {
        return request.get('data_board/getLuckBag', { params })
    }
    // 获取优惠卷下拉框数据
export const getCouponDrop = (params) => {
        return request.get('data_board/getCouponDropData', { params })
    }
    //获取优惠券
export const getCoupon = (params) => {
    return request.get('data_board/getCoupon', { params })
}
export const liveRoom_getScriptFile = (params) => {
    return http.get('api/stu/liveRoom/getScriptFile', { params })
}
export const liveRoom_save = (params) => {
        return http.post('api/stu/liveRoom/save', params)
    }
    //直播复盘面板
export const getReviewData = (params) => {
        return http.get('data_review/getReviewData', { params })
    }
    //直播复盘商品热度趋势
export const getGoodsTrend = (params) => {
    return http.get('data_review/getGoodsTrend', { params })
}

/** 教师端-数据中心 */
//获取班级实训下拉框数据
export const getBanjiAndTrain = (params) => {
        return http.get('data_center/getBanjiAndTrain', { params })
    }
    //获取详细数据
export const getDetailData = (params) => {
        return http.get('data_center/detailData', { params })
    }
    //直播复盘时间轴
export const getTimeLive = (params) => {
        return http.get('data_review/getTimeLive', { params })
    }
    //直播复盘记录详情
export const getOneDetail = (params) => {
    return http.get('data_review/getOneDetail', { params })
}